import React, { useState, useEffect } from 'react';

const Projects = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch('https://api.chiscari.ro')
      .then(response => {
        return response.json();
      })
      .then(data => {
        setData(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  let sortedData = [...data].sort((a, b) => b.id - a.id);

  // If a category is selected, filter posts by that category
  if (selectedCategory) {
    sortedData = sortedData.filter(post => post.categories.includes(selectedCategory));
  }

  let currentPosts = sortedData.slice(indexOfFirstPost, indexOfLastPost);

  // Extract all categories from all posts
  const allCategories = data.reduce((categories, post) => {
    return categories.concat(post.categories);
  }, []);

  // Remove duplicates
  const uniqueCategories = [...new Set(allCategories)];

  // Change page
  const paginate = pageNumber => {
    const totalPages = Math.ceil(sortedData.length / postsPerPage);
    if (pageNumber > totalPages) {
      setCurrentPage(1);
    } else if (pageNumber < 1) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
    window.scrollTo(0, 0); // Scroll to top
  };

  // Filter posts by category
  const filterByCategory = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);  // Reset to first page
  };

  return (
    <section>
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <div>
          <h2>Blog</h2>

          {/* Display unique categories */}
          <div>
            <button 
              className={selectedCategory === null ? 'active' : ''} 
              onClick={() => setSelectedCategory(null)}
            >
              All
            </button>
            {uniqueCategories.map((category, i) => (
              <button 
                key={i} 
                className={selectedCategory === category ? 'active' : ''} 
                onClick={() => filterByCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>

          {currentPosts.map((item, index) => (
            <div key={index} className='boxPost'>
              <h2>{item.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          ))}

          <div>
            <button onClick={() => paginate(currentPage - 1)}>Previous</button>
            {[...Array(Math.ceil(sortedData.length / postsPerPage)).keys()].map(number => (
              <button 
                key={number + 1} 
                className={currentPage === number + 1 ? 'active' : ''} 
                onClick={() => paginate(number + 1)}
              >
                {number + 1}
              </button>
            ))}
            <button onClick={() => paginate(currentPage + 1)}>Next</button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Projects;
